<template>
  <div
    class="login_box"
    @click="selectShow = false"
    v-loading="$store.state.metaShow"
    :element-loading-text="$store.state.loginText"
  >
    <el-row type="flex" class="login">
      <el-col :xs="24" :sm="12" class="login_left">
        <h2>VisionMoney</h2>
      </el-col>
      <el-col :xs="24" :sm="12" class="login_right">
        <div class="login_content" v-if="!isGetCode">
          <h2>
            {{ $t("forgetPasswordLabel") }}
          </h2>
          <div class="countries" v-if="loginType === 'phone'">
            <vue-country-intl
              v-model="phoneCode.dialCode"
              schema="popover"
              @onChange="handleCountryChange"
              search-input-placeholder=""
            >
              <div class="phoneCode" slot="reference">
                <div>
                  <!-- 选择国家 -->
                  {{ $t("selectCountry") }}
                  : +{{ phoneCode.dialCode }}
                  <span :class="'iti-flag ' + phoneCode.code"> </span>
                </div>
                <i class="iconfont icon-xia"> </i></div
            ></vue-country-intl>
          </div>
          <div
            class="input_box"
            :class="{ borderR: phoneShow === 3, borderY: phoneShow === 2 }"
          >
            <input
              type="text"
              :placeholder="
                loginType === 'phone' ? $t('inputPhone') : $t('inputEmail')
              "
              v-model="phone"
              @blur="getBlur('phone')"
              :class="{ red: phoneShow === 3 }"
              @focus="getfocus('phoneShow')"
            />
          </div>
          <div
            class="input_box"
            :class="{
              borderR: yzmShow === 3 && yzmBtn,
              borderY: yzmShow === 2,
            }"
          >
            <input
              type="text"
              maxlength="6"
              :placeholder="$t('inputCode')"
              class="yzm"
              v-model="yzm"
              @blur="getBlur('yzmShow')"
              :class="{ red: yzmShow === 3 && yzmBtn }"
              @focus="getfocus('yzmShow')"
            />
            <button
              :class="yzmBtnShow ? 'yzmShow' : ''"
              @click="getyzm()"
              :disabled="yzmBtn"
            >
              {{ !yzmBtn ? $t("codeTips") : yzmText }}
            </button>
          </div>
          <div class="type_button">
            <span @click="setLoginType()" class="blue">
              {{ loginType === "phone" ? $t("emailLogin") : $t("phoneLogin") }}
            </span>
          </div>
          <h6 v-if="yzm.length == 0 && isGetYzm">
            {{ $t("fillCode") }}
          </h6>
          <div class="backLogin">
            <span @click="$router.replace({ name: 'login' })">{{
              $t("backLogin")
            }}</span>
          </div>

          <div>
            <button
              class="login_button2 next_button"
              @click="nextHandle"
              :class="{
                yz_button: yzm.length > 0 && yzmBtn,
              }"
              :disabled="yzm.length == 0 && yzmBtn"
            >
              <!-- 下一步 -->
              {{ $t("next") }}
            </button>
          </div>
        </div>
        <div class="login_content" v-if="!toSetPassword && isGetCode">
          <h2>
            <!-- 助记词输入 -->
            {{ $t("forgetPasswordLabel") }}
          </h2>
          <div class="mem_input_box">
            <p>{{ $t("check_mem") }}</p>
            <textarea
              type="text"
              class="mem_input"
              v-model="Phonefrom.mem.value"
            />
          </div>
          <div class="backLogin">
            <span
              @click="
                isGetCode = false;
                init(false);
              "
              >{{ $t("previousStep") }}</span
            >
          </div>
          <h6 v-if="memTipShow">
            <!-- 请输入助记语 -->
            {{ $t("memTipShow") }}
          </h6>
          <button
            class="mem_button"
            @click="debounceMemDone()"
            :class="{
              yz_button: Phonefrom.mem.value != '',
            }"
            :disabled="Phonefrom.mem.value == ''"
          >
            <!-- 注册 -->
            {{ $t("affirm") }}
          </button>
        </div>
        <!-- 设置密码 -->
        <div class="login_content" v-if="toSetPassword && isGetCode">
          <h2>
            <!-- 设置密码 -->
            {{ $t("settingPassWordTips") }}
          </h2>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.password.rules === 3,
              borderY: Phonefrom.password.rules === 2,
            }"
          >
            <input
              :type="passwordEye ? 'text' : 'password'"
              :placeholder="$t('settingPassWord')"
              v-model="Phonefrom.password.value"
              :class="{ red: Phonefrom.password.rules === 3 }"
              @focus="getfocus('password')"
            />
            <img
              v-if="!passwordEye"
              src="../../assets/images/list_icon_eye_1@2x.png"
              alt=""
              @click="passwordEye = !passwordEye"
            />
            <img
              v-if="passwordEye"
              src="../../assets/images/list_icon_eye_2@2x.png"
              alt=""
              @click="passwordEye = !passwordEye"
            />
          </div>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.password2.rules === 3,
              borderY: Phonefrom.password2.rules === 2,
            }"
          >
            <input
              :type="password2Eye ? 'text' : 'password'"
              :placeholder="$t('settingPassWord2')"
              v-model="Phonefrom.password2.value"
              :class="{ red: Phonefrom.password2.rules === 3 }"
              @blur="
                getBlur('password2');
                checkpasswordShow();
                checkPasswordLength();
              "
              @focus="getfocus('password2')"
            />
            <img
              v-if="!password2Eye"
              src="../../assets/images/list_icon_eye_1@2x.png"
              alt=""
              @click="password2Eye = !password2Eye"
            />
            <img
              v-if="password2Eye"
              src="../../assets/images/list_icon_eye_2@2x.png"
              alt=""
              @click="password2Eye = !password2Eye"
            />
          </div>
          <h6 v-if="passwordShow">
            <!-- 两次输入密码不相符 -->
            {{ $t("passwordError") }}
          </h6>
          <h6 v-if="passwordLengthShow">
            <!-- 密码长度不够提示 -->
            {{ $t("passwordLenthTip") }}
          </h6>
          <h4>
            <!-- 密码提示 -->
            {{ $t("passwordTips") }}
          </h4>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.passwordTips.rules === 3,
              borderY: Phonefrom.passwordTips.rules === 2,
            }"
          >
            <input
              type="text"
              :placeholder="$t('passwordTips2')"
              v-model="Phonefrom.passwordTips.value"
              @focus="getfocus('passwordTips')"
              @blur="getBlur('passwordTips')"
            />
          </div>
          <button
            class="login_button2 finished_button"
            @click="debounceLogin()"
            :class="{
              yz_button: confirmDisable,
            }"
            :disabled="!confirmDisable"
            v-loading="subPasswordLoad"
          >
            <!-- 注册 -->
            {{ $t("Finished") }}
          </button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import countries from "@/common/js/countryList";
import { loginVerification, logincheck } from "@/api/api";
import {
  forgetPassword,
  forgetPasswordVerification,
  getTag,
  updatePassword,
  updateProject,
} from "../../api/api";
// import axios from 'axios'
// import CryptoJS from 'crypto-js'
export default {
  data() {
    return {
      passwordLengthShow: false,
      passwordEye: false,
      password2Eye: false,
      passwordShow: false,
      timeNub: 60,
      phone: "",
      loginType: this.$store.state.loginType,
      countries: countries,
      isGetCode: false,
      selectShow: false,
      phoneCode: {
        code: "cn",
        name: "China (中国)",
        dialCode: 86,
        phoneFormat: "131 2345 6789",
      },
      yzm: "",
      yzmShow: false,
      yzmText: this.$t("codeTips"),
      yzmBtn: false,
      yzmBtnShow: false,
      isGetYzm: false,
      toSetPassword: false,
      subPasswordLoad: false,
      memTipShow: false,
      localuserData: JSON.parse(localStorage.getItem("localuserData")),
      Phonefrom: {
        // originPassword: {
        //   rules: 1,
        //   value: "",
        // },
        mem: {
          rules: 1,
          value: "",
        },
        password: {
          rules: 1,
          value: "",
        },
        password2: {
          rules: 1,
          value: "",
        },
        passwordTips: {
          rules: 1,
          value: "",
        },
      },
    };
  },
  computed: {
    tip1() {
      return this.$t("codeTips");
    },
    // passwordShow() {
    //   if (this.Phonefrom.password2.value) {
    //     return this.Phonefrom.password.value !== this.Phonefrom.password2.value;
    //   } else {
    //     return false;
    //   }
    // },
    confirmDisable() {
      return (
        this.Phonefrom.password.value !== "" &&
        this.Phonefrom.password2.value !== "" &&
        this.Phonefrom.passwordTips.value !== "" &&
        this.Phonefrom.mem.value !== ""
      );
    },
  },
  watch: {
    phone() {
      if (this.phone) {
        this.yzmBtnShow = true;
      } else {
        this.yzmBtnShow = false;
      }
    },
    "$store.state.metaShow": function () {
      // console.log(this.$store)
      let userMeta = this.$store.state.userMeta;
      console.log(userMeta);
      if (!this.$store.state.metaShow) {
        // console.log()
        if (userMeta.showId && userMeta.infoTxId && userMeta.name) {
          if (this.$route.query.from) {
            window.location.href = this.$route.query.from + "?type=registered";
          } else {
            setTimeout(() => {
              this.gotoUrl("index");
            }, 1000);
          }
        } else {
          return this.$message.error(this.$t("metaTxt"));
        }
      }
    },
  },
  methods: {
    handleCountryChange(item) {
      console.log(item);
      this.phoneCode.code = item.iso2;
      this.phoneCode.dialCode = item.dialCode;
    },
    checkPasswordLength() {
      if (this.Phonefrom.password2.value && this.Phonefrom.password.value) {
        if (this.Phonefrom.password2.value !== this.Phonefrom.password.value) {
          this.passwordShow = true;
        } else {
          this.passwordShow = false;
        }
      }
      if (
        (this.Phonefrom.password.value &&
          this.Phonefrom.password.value.length < 6) ||
        this.Phonefrom.password.value.length > 32
      ) {
        this.passwordLengthShow = true;
      }
      if (
        (this.Phonefrom.password2.value &&
          this.Phonefrom.password2.value.length < 6) ||
        this.Phonefrom.password2.value.length > 32
      ) {
        this.passwordLengthShow = true;
      }
      if (
        this.Phonefrom.password.value &&
        this.Phonefrom.password.value.length >= 6 &&
        this.Phonefrom.password.value.length <= 32
      ) {
        this.passwordLengthShow = false;
      }
      if (
        this.Phonefrom.password2.value &&
        this.Phonefrom.password2.value.length >= 6 &&
        this.Phonefrom.password2.value.length <= 32
      ) {
        this.passwordLengthShow = false;
      }
      return this.passwordLengthShow && !this.passwordShow;
    },
    checkpasswordShow() {
      if (this.Phonefrom.password.value == this.Phonefrom.password2.value) {
        this.passwordShow = false;
      } else {
        this.passwordShow = true;
      }
    },
    init(all = true) {
      if (all) {
        this.phone = "";
        this.loginType = this.$store.state.loginType;
        this.phoneCode = {
          code: "cn",
          name: "China (中国)",
          dialCode: 86,
          phoneFormat: "131 2345 6789",
        };
      }
      this.countries = countries;
      this.isGetCode = false;
      this.selectShow = false;
      this.yzm = "";
      this.yzmShow = false;
      this.yzmText = this.$t("codeTips");
      if (this.phone) {
        this.yzmBtnShow = true;
      } else {
        this.yzmBtnShow = false;
      }
      this.isGetYzm = false;
      this.toSetPassword = false;
      this.subPasswordLoad = false;
      this.memTipShow = false;
      this.yzmBtn = false;
      this.passwordLengthShow = false;
      this.passwordEye = false;
      this.password2Eye = false;
      this.passwordShow = false;
      this.localuserData = JSON.parse(localStorage.getItem("localuserData"));
      this.clearYZMText();
    },
    setLoginType(isCheck = true) {
      if (isCheck) {
        this.loginType === "phone"
          ? (this.loginType = "email")
          : (this.loginType = "phone");
      }
      this.$ShowMoney.noneMnemonic.setLoginType(this.loginType);
      this.$store.dispatch("loginType", this.loginType);

      this.init();
      // console.log(this.loginType)
    },
    nextHandle() {
      if (this.yzm == "") {
        return;
      }
      this.isGetCode = true;
    },
    backMemo() {
      this.isGetCode = true;
      this.toSetPassword = false;
    },
    setPhoneNub() {
      let phone = this.phone;
      if (this.phoneCode.dialCode != 86 && this.loginType === "phone") {
        phone = this.phoneCode.dialCode + this.phone;
      }
      return phone;
    },
    getyzm() {
      if (!this.phone) {
        return this.$message.error(
          this.loginType === "phone"
            ? this.$t("phoneNull")
            : this.$t("emailNull")
        );
      }
      let re =
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,16}$/;
      if (this.loginType === "email" && !re.test(this.phone)) {
        return this.$message.error(this.$t("emaiErr"));
      }
      let phone =
        (this.phoneCode.dialCode != 86 && this.loginType === "phone"
          ? "+"
          : "") + this.setPhoneNub();
      let params = {
        userType: this.loginType,
      };
      params.userType === "phone"
        ? (params.phone = phone)
        : (params.email = phone);
      this.yzmBtn = true;
      this.setYZMText();
      forgetPasswordVerification(params)
        .then(() => {
          this.$message({
            message: this.$t("codeSuccess"),
            type: "success",
          });
        })
        .catch((err) => {
          this.clearYZMText();
          return this.$message.error(this.$t("messageGetErr"));
        });
    },
    setYZMText() {
      this.yzmText = this.timeNub + "s";
      if (this.timeNub > 0) {
        this.timeC = setTimeout(() => {
          --this.timeNub;
          this.yzmText = this.timeNub + "s";
          this.setYZMText();
        }, 1000);
      } else {
        clearInterval(this.timeC);
        this.yzmBtn = false;
        this.timeNub = 60;
        this.yzmText = this.$t("codeTips");
      }
    },
    clearYZMText() {
      if (this.timeC) {
        clearTimeout(this.timeC);
        this.yzmBtn = false;
        this.timeNub = 60;
        this.yzmText = this.$t("codeTips");
      }
    },
    GetPhone(item) {
      this.phoneCode = item;
    },
    getfocus(type) {
      this[type] = 2;
    },
    getBlur(type) {
      if (!this[type] && type !== "") {
        this[type + "Show"] = 3;
      } else {
        this[type + "Show"] = 1;
      }
    },

    debounceLogin() {
      if (this.checkPasswordLength()) {
        return false;
      }
      this.debounce(this.setNewPassword, 1000, true);
    },
    debounceMemDone() {
      this.debounce(this.memDone, 1000, true);
    },
    memDone() {
      if (this.Phonefrom.mem.value == "") {
        this.memTipShow = true;
        return;
      } else {
        this.toSetPassword = true;
      }
    },
    async setNewPassword() {
      if (this.subPasswordLoad || this.passwordShow) {
        return false;
      }
      let address = "";
      let tag = "new";
      const phone = this.setPhoneNub();
      const getTagRes = await getTag({
        userType: this.loginType,
        phone: this.loginType == "phone" ? phone : "",
        email: this.loginType == "email" ? phone : "",
      }).then((r) => {
        return r.tag;
      });
      if (getTagRes) {
        tag = getTagRes;
      } else {
        this.$message.error(this.$t("getTagError"));
        return false;
      }
      try {
        address = window.ShowMoney.bsv.Mnemonic.fromString(
          this.Phonefrom.mem.value
        )
          .toHDPrivateKey()
          .deriveChild(tag == "new" ? "m/44'/10001'/0'" : "m/44'/145'/0'")
          .deriveChild(0)
          .deriveChild(0)
          .privateKey.toAddress(
            this.$store.state.isTestnet
              ? window.ShowMoney.bsv.Networks.testnet.name
              : window.ShowMoney.bsv.Networks.mainnet.name
          )
          .toString();
      } catch (error) {
        this.$message.error(this.$t("Mnemonic_error"));
        this.backMemo();
        console.log("Mnemonic.fromString", error);
        this.subPasswordLoad = false;
        return;
      }
      this.subPasswordLoad = true;
      forgetPassword({
        userType: this.loginType,
        phone: this.loginType == "phone" ? phone : "",
        email: this.loginType == "email" ? phone : "",
        enCryptedMnemonic: this.$utils.aesEncryptMem(
          this.Phonefrom.mem.value,
          this.Phonefrom.password.value
        ),
        newPassword: this.$utils.encryptPassword(this.Phonefrom.password.value),
        affirmNewPassword: this.$utils.encryptPassword(
          this.Phonefrom.password2.value
        ),
        type: 2,
        address: address,
        remark: this.Phonefrom.passwordTips.value,
        code: this.yzm,
      })
        .then((r) => {
          this.subPasswordLoad = false;
          localStorage.clear();
          sessionStorage.clear();
          this.$message(this.$t("passwordresetsuccess"));
          this.$router.replace({
            name: "login",
          });
        })
        .catch((err) => {
          this.backMemo();
          this.$message.warning(this.$t("Mnemonic_error"));
          return;
        });
    },
  },
  destroyed() {
    this.clearYZMText();
  },
  created() {
    // this.yzmText = this.tip1
    console.log(this.yzmText);
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/intl.css";
.login_box {
  background: url("../../assets/images/home_bg.png");
  width: 100vw;
  background-size: cover;
  background-position: 50%;
  min-height: calc(100vh - 68px);
}
.login {
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 7vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.login_left {
  padding-left: 20px;
  h2 {
    margin-top: 22px;
    font-size: 45px;
    color: #fff;
  }
  h4 {
    font-size: 16px;
    margin-bottom: 25px;
    color: #bfc2cc;
    font-weight: normal;
  }
  p {
    font-size: 14px;
    color: #bfc2cc;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    i {
      width: 8px;
      height: 8px;
      background: rgba(234, 179, 0, 1);
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .app_downloads {
    display: flex;
    margin-top: 45px;
    a {
      width: 195px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.08);
      border: 1px solid rgba(255, 255, 255, 0.8);
      border-radius: 30px;
      margin-right: 22px;
      i {
        font-size: 22px;
        margin-right: 10px;
      }
    }
  }
}
.login_right {
  padding: 20px;
}
::v-deep .vue-country-intl .country-intl-input-wrap {
  border: unset;
}
::v-deep .vue-country-intl .country-intl-label {
  padding-left: 0;
  padding: 7px 16px;
  font-size: 14px;
}
.login_content {
  max-width: 390px;
  background: #fff;
  margin: 0 auto;
  border-radius: 17px;
  padding: 50px;
  .sign {
    display: flex;
    justify-content: space-between;
  }
  h6 {
    margin: 5px 0;
    font-size: 12px;
    color: #f56c6c;
  }
  h4 {
    margin-top: 20px;
    font-size: 16px;
  }
  p {
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
    color: #303133;
  }
  h2 {
    color: #303133;
    font-size: 26px;
    margin-bottom: 22px;
    cursor: pointer;
    font-weight: normal;
    font-family: PingFang SC;
  }
  .blod {
    font-weight: bold;
  }
  .countries {
    height: 40px;
    font-size: 15px;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #bfc2cc;
    .phoneCode {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .select_phone {
      position: absolute;
      height: 300px;
      left: 0;
      top: 95%;
      z-index: 1;
      background: #fff;
      width: 100%;
      overflow: auto;
      padding: 0 20px;
      border-radius: 5px;
      border: 1px solid #bfc2cc;
    }
    .select_phone::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #fff;
      border-radius: 10px;
    }
    .select_phone::-webkit-scrollbar-track {
      box-shadow: none;
      /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.1);
    }
    .select_phone::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
      background-color: #aaa;
    }
    .item_phone {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 40px;
      line-height: 40px;
      .item_name {
        width: 60%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
.mem_input_box {
  p {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    color: #303133;
    text-align: left;
    margin-bottom: 10px;
  }
  .mem_input {
    padding: 20px;
    width: 100%;
    height: 254px;
    background: #f5f5f5;
    border: 1px solid #dddddd;
    border-radius: 8px;
    font-size: 28px;
    font-weight: 600;
    color: #222222;
    line-height: 36px;
  }
}
.input_box {
  height: 60px;
  font-size: 15px;
  line-height: 60px;
  width: 100%;
  border-bottom: 1px solid #bfc2cc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 25px;
    height: 25px;
  }
  .yzmShow {
    background: #eab300;
  }
  .red {
    &::-webkit-input-placeholder {
      color: #f56c6c;
    }
    &:-moz-placeholder {
      color: #f56c6c;
    }
    &::-moz-placeholder {
      color: #f56c6c;
    }
    &:-ms-input-placeholder {
      color: #f56c6c;
    }
  }
  input {
    height: 59px;
    font-size: 15px;
    color: #303133;
    border: none;
    display: block;
    outline: none;
    width: 100%;
  }
  .yzm {
    width: 60%;
  }
  button {
    font-size: 12px;
    color: #f9f9f9;
    width: 96px;
    cursor: pointer;
    height: 36px;
    line-height: 36px;
    background: rgba(191, 194, 204, 1);

    border-radius: 5px;
  }
}
.borderY {
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid #eab300;
}
.borderR {
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid #f56c6c;
}
.type_button {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
  span {
    cursor: pointer;
    color: #606266;
    font-size: 15px;
  }
  .blue {
    color: #157bee;
  }
}
.login_button {
  width: 100%;
  height: 60px;
  cursor: pointer;
  background: rgba(191, 194, 204, 1);
  border-radius: 12px;
  color: #fff;
  font-size: 24px;
  line-height: 60px;
  display: block;
  margin: 0 auto;
  margin-top: 45px;
  text-align: center;
}
.yz_button {
  background: #eab300;
}
.mem_button {
  width: 100%;
  height: 60px;
  cursor: pointer;
  background: rgba(191, 194, 204, 1);
  border-radius: 12px;
  color: #fff;
  font-size: 24px;
  line-height: 60px;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.backLogin {
  margin-top: 68px;
  text-align: center;
  cursor: pointer;
  color: #606266;
}
.login_button2 {
  width: 100%;
  height: 60px;
  cursor: pointer;
  background: rgba(191, 194, 204, 1);
  border-radius: 12px;
  color: #fff;
  font-size: 24px;
  line-height: 60px;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.yz_button {
  background: #eab300;
}
::v-deep .el-loading-spinner .path {
  stroke: #eab300;
}
::v-deep .el-loading-mask {
  background-color: rgba(255, 255, 255, 0.5);
}
.user_home {
  ::v-deep .el-dialog {
    max-width: 600px;
    border-radius: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .login_left {
    height: calc(100vh - 80px);
  }
  .login_right {
    padding-bottom: 60px;
  }
  .login_button {
    margin-top: 45px;
  }
  .login_left .app_downloads a {
    font-size: 12px;
  }
  .login_content {
    padding: 25px !important ;
  }
  .user_home {
    ::v-deep .el-dialog {
      border-radius: 15px;
      width: 80%;
    }
  }
}
</style>
